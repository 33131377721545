.claim .titleDescr{
  z-index: 1;
  position: relative;
}
.claim .titleDescr p span{
  color: #F4CE4B;
}
.mainContent.claim .titleDescrImage{
  align-items: center;
}
.claim .titleDescrImage::before{
  clip-path: none;
  background-color: #0F152F;
}
.buyNFTs{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-bottom: 1em;
}
.buyDH a, .buySD a{
  margin-left: 1em;
  margin-right: 1em;
  padding: .5em;
  background-color: #194575;
  border-radius: .85em;
  color: #FEF8E9;
  text-decoration: none;
}
.floorprice{
  display: block;
  margin-top: 1em;
  font-size: .65em;
  text-transform: uppercase;
  color: #4BCEFF;
}
.floorprice span{
  margin-right: .3em;
  margin-left: .3em;
}
.tokenStats{
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.tokenStats > div{
  position: relative;
  padding:.5em 1.5em 1.5em;
  border-radius: .85em;
  background-color: #194575;
  margin-right: 3em;
  margin-left: 3em;
  line-height: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: .5em;
  box-shadow:inset 0 -5px 0 rgba(255,255,255,.5);
}
.tokenStats > div::before{
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #194575;
  height: 8em;
  margin-bottom: -3em;
  z-index: -1;
}
.tokenStats > div.vol{
  background-color: #2A984D;
}
.tokenStats > div.vol::before{
  background: -moz-radial-gradient(center, ellipse cover,  rgba(255,255,255,1) 0%, rgba(42,152,77,0.44) 39%, rgba(42,152,77,0) 70%, rgba(42,152,77,0) 100%);
  background: -webkit-radial-gradient(center, ellipse cover,  rgba(255,255,255,1) 0%,rgba(42,152,77,0.44) 39%,rgba(42,152,77,0) 70%,rgba(42,152,77,0) 100%);
  background: radial-gradient(ellipse at center,  rgba(255,255,255,1) 0%,rgba(42,152,77,0.44) 39%,rgba(42,152,77,0) 70%,rgba(42,152,77,0) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#002a984d',GradientType=1 );
}
.tokenStats > div.claimed{
  background-color: #D9AD4D;
  color: black;
}
.tokenStats > div.claimed::before{
  background: -moz-radial-gradient(center, ellipse cover,  rgba(255,255,255,1) 0%, rgba(217,173,77,0.44) 39%, rgba(217,173,77,0) 70%, rgba(217,173,77,0) 100%);
  background: -webkit-radial-gradient(center, ellipse cover,  rgba(255,255,255,1) 0%,rgba(217,173,77,0.44) 39%,rgba(217,173,77,0) 70%,rgba(217,173,77,0) 100%);
  background: radial-gradient(ellipse at center,  rgba(255,255,255,1) 0%,rgba(217,173,77,0.44) 39%,rgba(217,173,77,0) 70%,rgba(217,173,77,0) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#00d9ad4d',GradientType=1 );
}
.tokenStats > div.price{
  background-color: #377CCA;
}
.tokenStats > div.price::before{
  background: -moz-radial-gradient(center, ellipse cover,  rgba(255,255,255,1) 0%, rgba(55,124,202,0.44) 39%, rgba(55,124,202,0) 70%, rgba(55,124,202,0) 100%);
  background: -webkit-radial-gradient(center, ellipse cover,  rgba(255,255,255,1) 0%,rgba(55,124,202,0.44) 39%,rgba(55,124,202,0) 70%,rgba(55,124,202,0) 100%);
  background: radial-gradient(ellipse at center,  rgba(255,255,255,1) 0%,rgba(55,124,202,0.44) 39%,rgba(55,124,202,0) 70%,rgba(55,124,202,0) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#00377cca',GradientType=1 );
}
.tokenStats > div.mc{
  background-color: #DA6694;
  color: black;
}
.tokenStats > div.mc::before{
  background: -moz-radial-gradient(center, ellipse cover,  rgba(255,255,255,1) 0%, rgba(218,102,148,0.44) 39%, rgba(218,102,148,0) 70%, rgba(218,102,148,0) 100%);
  background: -webkit-radial-gradient(center, ellipse cover,  rgba(255,255,255,1) 0%,rgba(218,102,148,0.44) 39%,rgba(218,102,148,0) 70%,rgba(218,102,148,0) 100%);
  background: radial-gradient(ellipse at center,  rgba(255,255,255,1) 0%,rgba(218,102,148,0.44) 39%,rgba(218,102,148,0) 70%,rgba(218,102,148,0) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#00da6694',GradientType=1 );
}
.tokenStats > div.holders{
  background-color: #916CB4;
}
.tokenStats > div.holders::before{
  background: -moz-radial-gradient(center, ellipse cover,  rgba(255,255,255,1) 0%, rgba(145,108,180,0.44) 39%, rgba(145,108,180,0) 70%, rgba(145,108,180,0) 100%);
  background: -webkit-radial-gradient(center, ellipse cover,  rgba(255,255,255,1) 0%,rgba(145,108,180,0.44) 39%,rgba(145,108,180,0) 70%,rgba(145,108,180,0) 100%);
  background: radial-gradient(ellipse at center,  rgba(255,255,255,1) 0%,rgba(145,108,180,0.44) 39%,rgba(145,108,180,0) 70%,rgba(145,108,180,0) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#00916cb4',GradientType=1 );
}
.tokenStats > div h3{
  margin-top: 0;
  margin-bottom: .85em;
}
.tokenStats > div > div{
  font-size: 2em;
}
.valueamountHump{
  font-size: .5em;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 0 auto .5em
}
.connectedWallet{
  margin-bottom: 1em;
  font-family: "Headings";
}
.connectedWallet span{
  color: #5BF18C;
}
.amountNFT{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.amountNFT > div{
  margin-right: .5em;
  margin-left: .5em;
  font-family: "Headings";
}
.amountSD, .amountDH{
  font-size: 1.2em;
}
.totalClaimed{
  font-size: .85em;
  color: #909DD3;
  margin-bottom: 2em;
  margin-bottom: 5em;
}
.claimTokens{
  position: relative;
  background-color: #263059;
  border: 3px solid #455391;
  border-radius: .85em;
  display: inline-block;
  padding: 2em;
  padding-left: 4em;
}
.claimHump{
  background-color: #2A984D;
  position: relative;
  padding: 0.5em 1em;
  font-size: 1.35em;
  font-family: "Headings";
  color: #FEF8E9;
  text-transform: uppercase;
  border: none;
  border-radius: .85em;
  margin-top: .5em;
  z-index: 5;
  border: 2px solid #5BF18C;
}
.tokensImg{
  display: block;
  margin: 0 auto;
  max-width: 200px;
  margin-bottom: 1em;
  position: absolute;
  top: 0;
  left: 0;
  margin-top: -3em;
  margin-left: -3em;
}
.titleClaim{
  font-family: "Headings";
}
.amountTokens{
  background-color: #455391;
  border-radius: .85em;
  padding: .5em;
}
.tokensclaim{
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding-left: 50px;
}
.tokensclaim button{
  cursor: pointer;
}
.tokensclaim button:hover{
  animation: bump .5s forwards ease-in-out;
  transform: scale(1);
}
@media (min-width:768px){
  .claim .titleDescr{
    width: 50%;
    padding-right: 1.5em;
  }
  .tokenStats{
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 1em;
  }
  .tokenStats > div{
    margin-right: .5em;
    margin-left: .5em;
    margin-bottom: 4em;
    padding:.5em 1.5em 1.85em;
  }
  .tokenStats > div:first-child{
    margin-left: 0;
  }
  .tokenStats > div:last-child{
    margin-right: 0;
  }
}
@media (min-width:1366px){

}