.mainContent{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
img{
  z-index: 1;
}
/*first section*/
section{
  position: relative;
  padding-top: 90px;
  padding-bottom: 6vh;
  width: 100%;
  display: flex;
  margin-top: 0;
  justify-content: center;
  z-index: 2;
  flex-direction: column;
  overflow: hidden;
}
.titleDescrImage::before, .rewarding::before, .tokenomic::before, .utility::before{
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-image: url('../imgs/pattern.png');
}
.titleDescrImage::before{
  background-color: #073E3E;
  clip-path: polygon(0 0, 100% 0, 100% 90%, 0% 100%);
}
.titleDescr{
  z-index: 1;
  position: relative;
  padding-left: 1.5em;
  padding-right: 1.5em;
}
.titleDescrImage .titleDescr{
  text-align: center;
}
.titleDescr h2{
  color: #F4CE4B;
  font-size: 2em;
  margin: 0;
}
.titleDescr h2 img{
  max-width: 100%;
}
.titleDescr h2 span{
  display: block;
  color: #FEF8E9;
  font-size: .65em;
}
.titleDescr p{
  color: #FEF8E9;
}
.linkContract{
  color: #77EF9E;
  text-decoration: none;
  font-size: .75em;
}
.linkContract i{
  vertical-align: middle;
  margin-left: .3em;
}
.uniswapBuy, .claimHumpBoard{
  position: relative;
  padding: .5em 1em;
  font-size: 1.35em;
  font-family: "Headings";
  color: #FEF8E9;
  text-transform: uppercase;
  border: none;
  border-radius: .5em;
  margin-top: 2.5em;
  z-index: 5;
}
.uniswapBuy{
  background-color: #FF0079;
}
.uniswapBuy i{
  vertical-align: middle;
  margin-right: .25em;
}
.imgDescr{
  position: relative;
  display: block;
  margin-left: auto;
  margin-right: auto;
  height: auto;
  max-width: 75%;
  margin-top: 1em;
}
/*first section end*/
/*second section*/
.rewarding{
  z-index: 1;
  margin-top: -30%;
  padding-top: 30%;
  padding-bottom: 40%;
  flex-direction: column-reverse;
}
.rewarding::before{
  background-color: #3F5B7B;
}
.rewarding h3, .tokenomic h3, .utility h3{
  font-size: 2.4em;
  position: relative;
  display: inline-block;
  text-align: center;
}
.rewarding h3 span, .tokenomic h3 span, .utility h3 span{
  display: inline-block;
  position: relative;
  color: #F4CE4B;
  margin-left: .25em;
}
.rewarding h3::before, .tokenomic h3::before, .utility h3::before{
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-image: url(../imgs/bg_title_blue.png);
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
  z-index: -1;
}
.rewarding p a{
  color: #7DB7FF;
}
.claimHumpBoard{
  background-color: #197A39;
  border: 3px solid #35B065;
  display: block;
  margin: 1em auto 0;
}
.claimHumpBoard:hover{
  animation: bump .5s forwards ease-in-out;
  transform: scale(1);
}
@keyframes bump {
  25%{
    transform: scale(.8);;
  }
  75%{
    transform: scale(1.2);;
  }
  100%{
    transform: scale(1);;
  }
}
.claimButton, .claimButton button, .externalLink button{
  text-decoration: none;
  cursor: pointer;
}
.linksOS{
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.linkOS{
  text-align: center;
}
linkOS a{
  display: block;
}
.linkOS h4{
  position: relative;
  font-size: 1em;
  text-transform: uppercase;
  text-align: center;
  padding: .5em 1em;
  background-color: #3270BD;
  border-style: solid;
  border-width: 3px;
  border-color: #4986D5;
  border-radius: .5em;
  margin: -1em .5em 0 0;
  z-index: 1;
}
.linkOS h4.dhnfts{
  background-color: #BC8D32;
  border-color: #E9A31D;
  margin: -1em 0 0 .5em;
}
.linkOS h4 a{
  color: #FEF8E9;
  text-decoration: none;
}
.linkOS img{
  border-radius: .5em;
  margin: 0 1em;

}
/*second section end*/
/*third section*/
.tokenomic{
  margin-top: 0;
  margin-top: -40%;
  padding-top: 5%;
  padding-bottom: 40%;
}
.tokenomic::before{
  background-color: #2D9C96;
  clip-path: polygon(0 0, 100% 10%, 100% 90%, 0% 100%);
}
.tokenomic h3::before{
  background-image: url(../imgs/bg_title_green.png);
}
.piechartList{
  display: flex;
  flex-direction: column;
}
.tokenomic ul.tokenInfo{
  list-style: none;
  padding-left: 0;
}
.tokenomic ul.tokenInfo li:first-child{
  color: #F4CE4B;
}
.tokenomic ul li span{
  position: relative;
  display: inline-block;
  color: #FEF8E9;
  text-transform: uppercase;
  margin-right: 1em;
}
.tokenomic ul li a{
  color: #77EF9E;
}
.tokenImgBuy{
  text-align: center;
}
.tokenomic .uniswapBuy{
  margin-top:0;
}
ul.allos{
  list-style: none;
}
ul.allos li:before{
  content: "";
  width: 23px;
  height: 23px;
  border: 3px solid black;
  border-radius: 50%;
  display: inline-block;
  vertical-align: middle;
  margin-bottom: .5em;
  margin-right: .3em;
}
ul.allos li:first-child:before{
  background-color: #3BA9A3;
}
ul.allos li:nth-child(2):before{
  background-color: #BB8517;
}
ul.allos li:nth-child(3):before{
  background-color: #45B76D;
}
ul.allos li:nth-child(4):before{
  background-color: #436B9B;
}
ul.allos li:last-child:before{
  background-color: #B55D6B;
}
.contractAddr{
  font-size: .85em;
}
/*third section end*/
/*fourth section*/
.utility{
  z-index: 1;
  margin-top: -40%;
  padding-top: 40%;
  flex-direction: column-reverse;
}
.utility::before{
  background-color: #25576F;
  background-image: url('../imgs/pattern.png');
}
.utility h3::before{
  background-image: url(../imgs/bg_title_dark.png);
}
.utility p a{
  color: #3270BD;
}
.utility .linksOS{
  align-items: center;
}
.iconX{
  height: 100%;
}
.moreInfo{
  font-family: "Headings";
  font-size: 1.5em;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  transform: rotateZ(5deg);
  margin-top: -40px;
}
.utility .titleDescr{
  text-align: center;
}
/*fourth section end*/
@media (min-width:768px){
  .imgDescr{
    height: fit-content;
    margin-right: -200px;
    max-height: unset;
    margin-top: -35em;
    max-width: unset;
  }
  .tokenomic .imgDescr{
    top: 0;
    margin-right: -100px;
  }
  .rewarding{
    margin-top: -10%;
    padding-top: 13%;
    padding-bottom: 13%;
  }
  .rewarding, .utility{
    flex-direction: row;
  }
  .rewarding .imgDescr, .utility .imgDescr{
    left: 0;
    margin-left: -200px;
    margin-top: 0;
  }
  .titleDescr{
    padding-right: 300px;
  }
  .rewarding .titleDescr, .utility .titleDescr{
    padding-left: 300px;
    padding-right: unset;
  }
  .tokenomic{
    margin-top: 0;
    margin-top: -10%;
    padding-top: 5%;
    padding-bottom: unset;
  }
  .tokenomic .uniswapBuy{
    margin-top:-5em;
    position: absolute;
    right: 0;
    margin-right: .5em;
  }
  .piechartList{
    flex-direction: row;
    align-items: center;
  }
  .linkContract{
    font-size: 1em;
  }
  .contractAddr{
    font-size: 1em;
  }
  .utility{
    margin-top: -10%;
    padding-top: 10%;
  }
  .rewarding h3, .tokenomic h3, .utility h3{
    font-size: 2.8em;
  }
}
@media (min-width:1366px){
  .titleDescr{
    width: 40%;
    padding-right: 0;
  }
  .imgDescr{
    position: relative;
    height: fit-content;
    margin-left: 23px;
    margin-right: unset;
  }
  .imgDescr{
    margin-top: 0;
    max-width: unset;
  }
  .rewarding .imgDescr, .utility .imgDescr{
    margin-left: 23px;
  }
  section{
    flex-direction: row;
  }
  .tokenomic .imgDescr{
    margin-right: 0;
  }
  .tokenomic .uniswapBuy{
    position: relative;
    margin-top:0;
  }
  .rewarding .titleDescr, .utility .titleDescr{
    padding-left: 0;
  }
}