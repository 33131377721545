footer{
  padding: 3em;
  color: #497E98;
  background-color: #0F2C3B;
  font-size: .75em;
  display: flex;
  flex-direction: column;
}
.infoFooter{
  position: relative;
  width: 100%;
}
footer p{
  text-align: center;
  padding-left: .5em;
  padding-right: .5em;
}
.linkFooter {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.linkFooter .dextools{
  position: relative;
  display: block;
  z-index: 1;
  background-image: url(../../imgs/dextools_logo_full_dark.svg);
  width: 200px;
  height: 62px;
  background-size: contain;
  background-repeat: no-repeat;
}
@media (min-width:992px) {
  footer{
  flex-direction: row;
  }
  .infoFooter, .linkFooter{
    width: 50%;
  }
}