@font-face {
  font-family: 'Headings';
  src: local('Headings'), url(../fonts/headingFont.woff) format('woff');
}
@font-face {
  font-family: 'Content';
  src: local('Content'), url(../fonts/contentFont.woff) format('woff');
}
body{
  font-size: 16px;
  margin: 0;
  padding-top: 3vh; 
  font-family: 'Content';
  color: #FEF8E9;
  background-color: black;
  width: 100%;
}
h1, h2, h3, h4, h5, h6{
  font-family: 'Headings';
}
* {
    scrollbar-width: 18px;
    scrollbar-color: #2A63A5 #083554
}

*::-webkit-scrollbar {
    width: 18px
}

*::-webkit-scrollbar-track {
    background: #083554
}

*::-webkit-scrollbar-thumb {
    background-color: #2A63A5;
    border-radius: 0;
    border: 0 solid #2A63A5
}

::-moz-selection {
    color: #FEF8E9;
    background: #1a73e8
}

::selection {
    color: #FEF8E9;
    background: #1a73e8
}
@media (min-width:1366px){
  body{
    font-size: 18px;
  }
}