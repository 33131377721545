header{
  position: fixed;
  display: flex;
  align-items: center;
  left: 0;
  top: 0;
  right: 0;
  height: 100px;
  background-color: #3FB365;
  font-family: 'Headings';
  z-index: 10;
}
nav.menuDesktop{
  position: relative;
  display: flex;
  display: flex;
  align-items: center;
  justify-content: center;
  display: none;
}
nav h1{
  width: 85px;
  height: 85px;
  margin: 0;
  margin-bottom: 0;
}
.mobileMenu h1{
  margin-bottom: 0;
}
.mobileMenu h1 a{
  display: block;
}
nav h1 img{
  width: 100%;
}
nav ul{
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  list-style: none;
}
nav ul li{
  padding-left: .25em;
  padding-right: .25em;
  padding-top: .65em;
}
nav ul li a{
  display: inline-block;
  color: #FEF8E9;
  text-decoration: none;
  padding:.5em 1em;
  border-radius: .5em;
  font-size: 1.2em;
}
nav ul li a.uniswap{
  background-color: #FF0079;
  border: 3px solid black;
}
nav ul li a.humpBoard{
  background-color: #197A39;
}
nav ul li a.strayScan{
  background-color: #A57F29;
}
nav ul li a.sdOpensea{
  background-color: #2A63A5;
}
nav ul li a.gifMakerPage{
  background-color: #083554;
}
nav ul li a:hover{
  animation: shake .5s forwards ease-in-out;
  transform: rotateZ(0deg);
}
@keyframes shake {
  25%{
    transform: rotateZ(15deg);
  }
  75%{
    transform: rotateZ(-15deg);
  }
  100%{
    transform: rotateZ(0deg);
  }
}
.walletButton{
  color: #FEF8E9;
  position: absolute;
  top: 0;
  right: 0;
  margin-right: 1em;
  background-color: unset;
  border: none!important;
}
.walletAddress{
  font-family: "headings";
  font-size: 1.1em;
  position: absolute;
  top: 0;
  right: 0;
  margin-top:-.5em;
  margin-right: calc(45px + 1em);
  padding: .3em;
  border-radius: .5em;
  line-height: 1em;
  border: 3px solid #FEF8E9;
  color: #FEF8E9;
  background-color: #197A39;
}
.connectWallet{
  position: relative;
  width: 45px;
  height: 52px;
  background-image: url(../../imgs/wallet.svg);
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  border: 0;
  background-color:transparent;
  cursor: pointer;
  margin-top: 15px;
  z-index: 10;
}
.connectWallet:hover, .social .icon:hover{
  animation: shake .5s forwards ease-in-out;
  transform: rotateZ(0deg);
  transform-origin: center;
}
.social{
  position: absolute;
  top: 0;
  margin-top: 20px;
  right: 0;
  margin-right: calc(45px + 1em);
  display: flex;
  flex-direction: row;
  height: 60px;
  align-items: center;
}
.social .icon{
display: block;
width: 26px;
height: 26px;
background-size: contain;
background-position: center;
background-repeat: no-repeat;
margin-left:.3em;
margin-right:.3em;
}
.social .icon:last-child{
  margin-right: .5em;
}
.social .icon:first-child{
  margin-left: 0;
}
.icon.twitter{
  background-image: url(../../imgs/icon_tw.png);
}
.icon.telegram{
  background-image: url(../../imgs/icon_tg.png);
}
.icon.discord{
  background-image: url(../../imgs/icon_dc.png);
}
.icon.opensea{
  background-image: url(../../imgs/icon_os.png);
}
/* MENU MOBILE*/
.mobileMenu h1{
  position: relative;
  margin-top: .1em;
  display: inline-block;
  z-index: 10;
}
.mobileMenu h1 img{
  width: 60px;
  margin-right: .5em;
  margin-left: .5em;
}
.toggleContainer{
  width: 80px;
  height: 60px;
  top: 0;
  margin: 0;
  position: relative;
  display: inline-block;
}
.toggleMobile{
  cursor: pointer;
  z-index: 10;
}
.toggleMobile, .toggleMobile::before, .toggleMobile::after{
  position: absolute;
  top: 0;
  background-color: transparent;
  width: 50px;
  height: 22px;
  background-image: url(../../imgs/bone.svg);
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  border-style: unset;
  transition: transform .2s;
}
.toggleMobile::before, .toggleMobile::after{
  position: absolute;
  content: "";
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
}
.toggleMobile::before{
  margin-top: 20px;
  margin-left: .5em;
}
.toggleMobile::after{
  margin-top: 40px;
  margin-left: 1em;
}
.mobileMenu nav{
  position: absolute;
  width: 0;
  height: 0;
  top: 0;
  left: 0;
  overflow: hidden;
  background-color: transparent;
  transition: background .3s;
}
.mobileMenu.show nav{
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.75);
}
.toggleMobile:hover, .toggleMobile:hover::after{
  transform: rotateZ(10deg);
}
.toggleMobile:hover::before{
  transform: rotateZ(-10deg);
}
.show .toggleMobile{
  transform: translateY(-50px);
}
.show .toggleMobile::before{
  transform: translateY(50px) rotate(45deg);
}
.show .toggleMobile::after{
  transform:translateY(23px) rotate(-45deg) translateX(-8px);
}
.mobileMenu nav ul{
  flex-direction: column;
  justify-content: center;
  height: 100%;
  padding-left: 0;
}
.mobileMenu nav ul li a{
  padding: 1em;
  font-size: 2em;
}
/* MENU MOBILE END*/
@media (min-width:450px){
  header{
    height: 80px;
  }
  .social{
    height: 52px;
  }
  .connectWallet{
    margin-top: 10px;
  }
}
@media (min-width:1366px){
  header{
    height: 70px;
    justify-content: center;
  }
  nav.menuDesktop{
    display: flex;
    margin-top: 30px;
  }
  .mobileMenu{
    display: none;
  }
}