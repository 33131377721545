.GifMaker{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  max-width: 1140px;
  min-height: 75vh;
  z-index: 1;
}
.logoHumpMaker{
  position: relative;
  width: 366px;
  height: 155px;
}
.logoHumpMaker img{
  max-width: 100%;
  max-height: 100%;
}
.logoHumpMaker::before{
  content:"V. BETA";
  position: absolute;
  bottom: 0;
  right: 0;
  color: red;
  background-color: white;
  border: 3px solid red;
  border-radius: .3em;
  padding: .5em .5em .25em;
  line-height: 1em;
  vertical-align: middle;
  transform: rotateZ(-15deg);
}
.canvasAndEditorContainer{
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #001817;
  padding: 2em;
  border: 3px solid #0D5A59;
  border-radius: 2em;
  margin-top: 2em;
  padding-top: 3em;
  padding-bottom: 4em;
  height: 100%;
}
#inputImg{
  display: none;
}
.sample-canvas {
  border-radius: 1em;
  width: 300px;
  height: 300px;
  overflow: hidden;
  display: block;
  position: relative;
}
#gifMaker::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-image: url('../../imgs/pattern.png');
    background-color: #073E3E;
    z-index: 0;
}
.canvasEditor{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.canvasEditor button, label.addPicture{
  display: inline-block;
  color: #FEF8E9;
  text-decoration: none;
  padding:.5em 1em .5em;
  border-radius: .5em;
  font-size: 1.2em;
  border-style: none;
  cursor: pointer;
  margin-bottom: .5em;
  color: black;
  font-size: .85em;
  text-align: left;
  position: relative;
}
label.addPicture, button.addHumperEditor, button.addTextEditor, button.startRecordingEditor, button.stopRecordingEditor{
  padding-left: 30px;
}
label.addPicture::before, button.addHumperEditor::before, button.addTextEditor::before, button.startRecordingEditor::before, button.stopRecordingEditor::before{
  content: "";
  position: absolute;
  left: 0;
  margin-left: .3em;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  width: 20px;
  height: 20px;
}
label.addPicture::before{
  background-image: url('../../imgs/icon_addImage.png');
}
button.addHumperEditor::before{
  background-image: url('../../imgs/icon_addHumper.png');
}
button.addHumperEditor{
  margin-left: .5em;
}
button.addTextEditor::before{
  background-image: url('../../imgs/icon_addText.png');
}
button.startRecordingEditor::before{
  background-image: url('../../imgs/icon_record.png');
}
button.stopRecordingEditor::before{
  background-image: url('../../imgs/icon_stop.png');
}
label{
  font-size: .85em;
}
label.textBrushColor{
  position: relative;
  margin-left: .3em;
}
label.addPicture, button.addTextEditor, button.startRecordingEditor{
  background-color:#D0D0D0;
}
.canvasEditor button.addHumperEditor{
  background-color: #1EF37D;
}
button.stopRecordingEditor{
  background-color: #4376D9;
}
#deleteSelected{
  position: absolute;
  right: 0;
  bottom: 0;
  margin-bottom: 1em;
  margin-right: 2em;
}
.canvasEditor button.deleteSelectedEditor{
  background-color: red;
  background-image: url(../../imgs/icon_delete.png);
  background-size: 18px 21px;
  background-repeat: no-repeat;
  background-position: center;
  width: 26px;
  height: 29px;
  padding: 0;
  border-radius: .15em;
  margin-left: .3em;
}
input[type="color"]{
  border: none;
  width: 30px;
  height: 30px;
  padding: 0;
  margin-left: .15em;
  clip-path: circle(30% at 50% 50%);
  line-height: 1em;
  vertical-align: middle;
}
.moreDrawingTools{
  padding: .5em;
  background-color:#073E3E;
  border-color: #0D5A59;
  border-radius: .5em;
  margin-bottom: 1em;
}
.slider{
  -webkit-appearance: none;
  appearance: none;
  width: 95%;
  height: 25px;
  background: rgba(255,255,255,.5);
  outline: none;
  opacity: 0.7;
  -webkit-transition: .2s;
  transition: opacity .2s;
  border-radius: .3em;
  padding: .3em;
}
.slider:hover {
  opacity: 1;
}
.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 25px;
  height: 25px;
  background: white;
  cursor: pointer;
  border-radius: .3em;
}
.slider::-moz-range-thumb {
  width: 25px;
  height: 25px;
  background: white;
  cursor: pointer;
  border-radius: .3em;
}
.brushShapeList select{
  position: relative;
  -webkit-appearance:none;
  -moz-appearance:none;
  appearance:none;
  display: inline-block;
  background-color: white;
  border-style: none;
  border: none;
  text-align: center;
  padding: .3em 30px .3em .5em;
  border-radius: .5em;
  border:1px solid white;
  color: white;
  background-color: transparent;
}
.brushShapeList .selectBrush{
  position: relative;
  display: inline-block;
}
.brushShapeList .selectBrush::before{
  content: "";
  display: inline-block;
  width: 15px;
  height: 7px;
  background-image: url(../../imgs/arrow_down.png);
  background-size: contain;
  background-position: center;
  position: absolute;
  right: 0;
  margin-right: 10px;
  top: 0;
  margin-top: 7px;
}
.ToggleDrawing{
  position: relative;
  height: 40px;
}
.ToggleDrawing button{
  position: absolute;
  display: inline-block;
  height: 30px;
  width: 50px;
  margin-left: .5em;
  background-color: rgb(173, 253, 173);
  transition: background-color .3s;
}
.ToggleDrawing button:before{
  content: "";
  position: absolute;
  background-color: green;
  height: 30px;
  width: 25px;
  top: 0;
  right: 0;
  left: 0;
  margin-left: calc(100% - 25px);
  border-radius: .5em;
  transition: margin-left .3s, background-color .3s;
}
.ToggleDrawing button.Enabled:before{
  content: "";
  position: absolute;
  background-color: red;
  margin-left: 0;
  height: 30px;
  width: 25px;
  top: 0;
  left: 0;
  right: 0;
  border-radius: .5em;
}
.ToggleDrawing button.Enabled{
  background-color: rgb(255, 160, 160);
}
.recordingTools{
  display: flex;
  flex-direction: column;
}
.startRecordingEditor,.stopRecordingEditor{
  display: block;
}
#dlDiv{
  display: flex;
  flex-direction: column;
  max-height: 100px;
  height: 100%;
  overflow-y: auto;
  background-color: rgba(255,255,255,.5);
  margin-bottom: 1em;
  padding: .5em;
  border-radius: .5em;
}
#dlDiv a{
  color: white;
  text-decoration: none;
  padding-left: 18px;
  position: relative;
}
#dlDiv a::before{
  content: "";
  background-image: url(../../imgs/icon_download.png);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  position: absolute;
  width: 15px;
  height: 15px;
  left: 0;
}
.DrawingTools .Enable{
  display: none;
}
@media (min-width:992px){
  .logoHumpMaker{
    width: 506px;
    height: 205px;
  }
  .sample-canvas {
    margin-left: 1em;
  }
  .canvasAndEditorContainer{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: unset;
    padding-bottom: 2em;
  }
  .sample-canvas {
    width: 500px;
    height: 500px;
  }
  #deleteSelected{
    top: 0;
    margin-top: 1em;
  }
}